import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import TrialAdditionalInformation from './TrialAdditionalInformation.jsx';

// import { lighten } from '@mui/styles/colorManipulator';
import { blue, red, yellow, orange, blueGrey, green, white } from '@mui/material/colors';

class EditTrialAdditionalInformationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open, trial: this.props.trial, statuses: {},
      sponsors: []
    };
    this.form = React.createRef();


  }


  componentDidUpdate(prevProps) {

  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('/trial_statuses', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
        currentComponent.setState({ statuses: data.statuses });
      });

    fetch('/trial_sponsors', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
      .then(function (response) { return response.json(); })
      .then(function (data) {
      // set DKFZ & NCT as default at beginning of array even if there are no other sponsors
        let temp = data.sponsors.filter(e => e !== 'DKFZ' && e !== 'UKHD')
        const vals = ['DKFZ', 'UKHD'];
        vals.forEach( e => temp.unshift(e))
        currentComponent.setState({ sponsors: temp });
      });
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    let trial = { ...this.state.trial };

    // if (name == "num_pat_goal" || name == "num_pat_included") {
    //   const onlyNumber = /\d+/;
    //   // console.log(value.match(onlyNumber));
    //   if (name == "num_pat_goal" && value != null && value.match(onlyNumber) && value == value.match(onlyNumber)[0]){
    //     trial[name] = value;
    //   }
    //   if (name == "num_pat_included" && value != null && value.match(onlyNumber) && value == value.match(onlyNumber)[0]){
    //     trial[name] = value;
    //   }
    //   if (value == null || value == "") {
    //     trial[name] = value;
    //   }
    // } else {
    trial[name] = value;
    // }
    currentComponent.setState({ trial: trial });
  };

  handleChange2 = (event, meta) => {
    const value = event.value;
    const name = meta.name;
    console.log(name + ' ' + value);
    let trial = { ...this.state.trial };
    trial[name] = value;
    this.setState({ trial: trial });
    if (meta.action == "create-option") {
      this.setState({ sponsors: [...this.state.sponsors, value] });
    }
  }


  handleClose = event => {
    this.props.handleClose();
  }

  render(props) {
    this.test
    const onlyNumber = /\d+/;
    // console.log(this.state.trial);
    // num_patients_included
    // const onlyNumberMatch = (this.state.zip && this.state.zip.toString().match(onlyNumber)) ? this.state.zip.toString().match(onlyNumber)[0] == this.state.zip : false;
    // console.log(this.state.trial.num_pat_goal.toString().match(onlyNumber));
    const numPatGoalOnlyNumberMatch = (((this.state.trial && this.state.trial.num_pat_goal && this.state.trial.num_pat_goal.toString().match(onlyNumber)) ? (this.state.trial.num_pat_goal.toString().match(onlyNumber)[0] == this.state.trial.num_pat_goal.toString()) : false));
    const numPatGoalIsOnlyNumber = (this.state.trial.num_pat_goal == null || this.state.trial.num_pat_goal == "" || numPatGoalOnlyNumberMatch);
    const numPatIncludedOnlyNumberMatch = (((this.state.trial && this.state.trial.num_patients_included && this.state.trial.num_patients_included.toString().match(onlyNumber)) ? (this.state.trial.num_patients_included.toString().match(onlyNumber) == this.state.trial.num_patients_included.toString()) : false));
    const numPatIncludedIsOnlyNumber = (this.state.trial.num_patients_included == null || this.state.trial.num_patients_included == "" || numPatIncludedOnlyNumberMatch);

    // console.log(numPatGoalOnlyNumberMatch, numPatGoalIsOnlyNumber, numPatIncludedOnlyNumberMatch, numPatIncludedIsOnlyNumber);

    const saveable = this.state.trial.title && this.state.trial.short && numPatGoalIsOnlyNumber && numPatIncludedIsOnlyNumber;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ style: { height: '700px' } }}
      >
        <DialogTitle id="form-dialog-title">Weitere Informationen editieren</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="start" alignItems="start">
            <Grid item xs={12} style={{ backgroundColor: blue[500] }}>
              <TrialAdditionalInformation
                trial={this.state.trial}
                handleChange={this.handleChange}
                handleChange2={this.handleChange2}
                readOnly={false}
                statuses={this.state.statuses}
                sponsors={this.state.sponsors}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={!saveable}
            onClick={() => this.props.handleSave({
              trial: this.state.trial,
            })}
            color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EditTrialAdditionalInformationDialog;
