import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from './TextField.jsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EnhancedTable from './EnhancedTable.jsx';
import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import FileUploadDialog from './FileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import EcrfMailToUserDialog from './EcrfMailToUserDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';
import DeleteConfirmationDialog from './DeleteConfirmationDialog.jsx';

const peopleColumns = [
  {
    id: "name",
    label: "Name",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
  {
    id: "first_name",
    label: "Vorname",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
  {
    id: "email",
    label: "Email",
    numeric: false,
    padding: 'none',
    searchable: true,
  },

  // {
  //   id: "personStatusArray",
  //   label: "Status",
  //   numeric: false,
  //   padding: 'none',
  //   searchable: true,
  //   filterable: true,
  //   type: "statusSelection"
  // },
]

const noteColumns = [
  {
   id: "entry_date",
   label: "Datum",
   numeric: false,
   disablePadding: false,
   date: true,
   type: "date",
  },
  {
   id: "note",
   label: "Notiz",
   numeric: false,
   disablePadding: false,
  },
 ];

class EcrfManagement extends React.Component {

  constructor(props) {
    super(props);
    let user = props.user;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ecrf_user_id = urlParams.get('ecrf_user_id');
    console.log(ecrf_user_id);
    // const process_step_id = urlParams.get('process_step_id');
    this.state = {ecrf_users: props.ecrf_users, selectedIds: {"Personen": [ecrf_user_id]}, user: user, expanded: null, openDialogs: {editPersonen: false}};
    this.form = React.createRef();
  }

  componentDidMount(props) {
  }

  handlePanelChange = panel => (event, expanded) => {
    let newExpanded = expanded;
    if (!this.state.user.name) {
      newExpanded = "user_details";
    } else if (!this.state.user.submitted_file_ids && expanded == "application") {
      newExpanded = "documents";
    }
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  handleRowsSelect = (rowData, allRowsSelected, allObjectsSelected, objName) => {
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[objName] = allRowsSelected;
    this.setState({
      selectedIds: newSelectedIds,
    });
    // this.updateDepartments(allRowsSelected);
  };

  // handleDoubleClick = (event, obj, name) => {
  //   console.log(event, obj, name)
  //   let newSelectedIds = {...this.state.selectedIds};
  //   newSelectedIds[name] = [obj.id];
  //   this.setState({selectedIds: newSelectedIds});
  //   this.handleOpen(name)
  // }

  handleDoubleClick = (event, obj, name) => {
    let newSelectedIds = {...this.state.selectedIds};
    newSelectedIds[name] = [obj.id]
    this.setState({selectedIds: newSelectedIds});
    this.handleOpen("edit" + name)
  }

  handleOpen = (name) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleNewEcrfUserSave = user => {
    const currentComponent = this;
    fetch('/ecrf_management/create_user', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user: user,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newUser");
        // console.log(data.ecrf_user);
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditEcrfUserSave = user => {
    const currentComponent = this;
    fetch('/ecrf_management/' + user.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user: user,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editPersonen");
        console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewApplicationSave = application => {
    console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.selectedIds["Personen"][0] + '/create_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newApplication");
        console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich abgeschickt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditApplicationStatusSave = application => {
    console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/update_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editApplication");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewNoteSave = note => {
    console.log(note);
    const currentComponent = this;
    fetch('/ecrf_management/create_user_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newNote");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditNoteSave = note => {
    console.log(note);
    const currentComponent = this;
    fetch('/polymorphic_notes/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editStory Board");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleDeleteEcrfUser = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_ecrf_users', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user_ids: this.state.selectedIds["Personen"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        // if (this.state.selectedPeople[0])
        let newOpenDialogs = {... currentComponent.state.openDialogs};
        newOpenDialogs["deleteUser"] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteUserNotes = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_user_notes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note_ids: this.state.selectedIds["Story Board"],
        ecrf_user_id: this.state.selectedIds["Personen"][0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleDeleteEcrfApplicationNotes = event => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_ecrf_application_notes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note_ids: this.state.selectedIds["Antrag Notizen"],
        ecrf_user_id: this.state.selectedIds["Personen"][0],
        // ecrf_application_id: this.state.selectedIds["Personen"][0],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleFileUpload = event => {
    this.setState({user: event});
    this.handleClose("fileUpload");
  }

  handleApplicationFileUpload = user => {
    this.setState({user: user});
    this.props.enqueueSnackbar('Erfolgreich hochgeladen.', {variant: 'success'});
    // this.handleClose("fileUpload");
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        ecrf_user_id: currentComponent.state.selectedIds["Personen"][0],

        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Konnte nicht gelöscht werden, da die Datei bereits akzeptiert wurde.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  // handleApplicationDelete = application => {
  //   const currentComponent = this;
  //   fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       application_id: application.id,
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     console.log(data);
  //     if (data.response == 1) {
  //       // var newTrial = data.trial;
  //       // console.log(data);
  //       currentComponent.setState({ user: data.ecrf_user });
  //       currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  handleApplicationRemove = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Löschen nicht möglich, da Benutzer bereits authorisiert.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeAccepted = (user_id) => {
    const currentComponent = this;
    console.log(this.state.user);
    const ecrf_user = this.state.ecrf_users.find(user => user.id == user_id)
    fetch('/ecrf_management/' + ecrf_user.id + '/mark_certificates_checked', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Allgemeine Dokumente erfolgreich akzeptiert.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Akzeptanz der allgemeinen Dokumente zurückgenommen.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeAcceptedTrialDocumentation = (application_id) => {
    const currentComponent = this;
    fetch('/ecrf_management/' + application_id + '/mark_trial_documentation_checked', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Studiendokumente erfolgreich akzeptiert.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Akzeptanz der Studiendokumente zurückgenommen.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeSubmittedApplication = (file_id) => {
    const currentComponent = this;
    const selectedUser = this.state.ecrf_users.find(user => user.id == this.state.selectedIds["Personen"][0]);
    const application = selectedUser.ecrf_applications.find(app => app.files.find(file => file.id == file_id) != -1);
    console.log(file_id, application);
    if (application.submitted_file_ids && application.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + selectedUser.id + '/mark_application_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + selectedUser.id + '/mark_application_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleChangePersonStatus = (event) => {
    // console.log( event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const id = this.state.selectedIds["Personen"];
    const currentComponent = this;
    const ecrf_user = this.state.ecrf_users.find(user => id == user.id);
    if (ecrf_user.person_status != value) {
      fetch('/ecrf_management/' + ecrf_user.id + '/change_person_status', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          status: value,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          // currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Status erfolgreich geändert.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleChangeApplicationStatus = (event) => {
    // console.log( event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const id = event.targetId;
    const currentComponent = this;
    const ecrf_user = this.state.ecrf_users.find(person => this.state.selectedIds["Personen"][0] == person.id);
    const application = ecrf_user.ecrf_applications.find(app => app.id == id);
    var site_name = "n.z.";
    if (application.site_id != null) {
      site_name = application.site.inst_description;
    }
    console.log(application, application.site_id != null);
    fetch('/ecrf_management/' + id + '/change_application_status', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        status: value,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Status erfolgreich geändert.', {variant: 'success'});
        let newEcrfUsers = [...currentComponent.state.ecrf_users]
        const ind = currentComponent.state.ecrf_users.findIndex(user => user.id == ecrf_user.id);
        newEcrfUsers[ind] = ecrf_user;
        currentComponent.setState({ecrf_users: newEcrfUsers}, console.log("test"));
        if (value == "ready") {
          window.open("mailto:" + ecrf_user.email + "?subject=eCRF Marvin –Antrag eCRF-Zugang erfolgreich geprüft [" + application.ecrf_trial_configuration.short + "], " + ecrf_user.name + ", " + ecrf_user.first_name + "&body=Sehr " + ecrf_user.name + ", " + ecrf_user.first_name + "&body=Guten Tag " + ((ecrf_user.person_title) ? ecrf_user.person_title + " " : "") + ecrf_user.first_name + " " + ecrf_user.name + ",%0D%0A%0D%0ADie Prüfung Ihres Antrags auf eCRF-Zugang und / oder der studienspezifischen Nachweise war erfolgreich.  Sie müssen nichts weiter unternehmen.%0D%0A%0D%0AWir haben Ihren Account jedoch noch nicht freigeschaltet, da entweder der eCRF insgesamt noch nicht freigeschaltet wurde oder Ihre Prüfstelle noch nicht freigegeben wurde. Wir werden Sie erneut informieren, sobald wir Ihren Account freischalten.%0D%0A%0D%0AAkronym der Studie: " + application.ecrf_trial_configuration.short + "%0D%0AFunktion in Studie: " + application.ecrf_trial_function.name + "%0D%0APrüfzentrum: " + site_name + "%0D%0ARolle in der Studie: " + application.ecrf_trial_role.name + "%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,");
        }
        if (value == "active") {
          window.open("mailto:" + ecrf_user.email + "?subject=eCRF Marvin –Antrag: eCRF-Zugang aktiviert [" + application.ecrf_trial_configuration.short + "], " + ecrf_user.name + ", " + ecrf_user.first_name + "&body=Guten Tag " + ((ecrf_user.person_title) ? ecrf_user.person_title + " " : "") + ecrf_user.first_name + " " + ecrf_user.name + ",%0D%0A%0D%0ADie Prüfung Ihres Antrags auf eCRF-Zugang und / oder der studienspezifischen Nachweise war erfolgreich und wir haben Ihren Account freigeschaltet. %0D%0A%0D%0AAkronym der Studie: " + application.ecrf_trial_configuration.short + "%0D%0AFunktion in Studie: " + application.ecrf_trial_function.name + "%0D%0APrüfzentrum: " + site_name + "%0D%0ARolle in der Studie: " + application.ecrf_trial_role.name + "%0D%0A%0D%0ADie Zugangsdaten erhalten Sie direkt aus dem Marvin-System mit dem Absender „noreply@xclinical.com“ mit folgendem Betreff „Your participation in project …“. Prüfen Sie hierzu ggf. Ihren Spam-Ordner.%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,");
        }
        if (value == "user interaction necessary") {
          window.open("mailto:" + ecrf_user.email + "?subject=eCRF Marvin – Bitte Personendaten / Nachweise bearbeiten " + ecrf_user.name + ", " + ecrf_user.first_name + "&body=Guten Tag " + ((ecrf_user.person_title) ? ecrf_user.person_title + " " : "") + ecrf_user.first_name + " " + ecrf_user.name + ",%0D%0A%0D%0ADie Prüfung Ihrer persönlichen Daten und / oder der allgemeinen Nachweise war leider nicht erfolgreich.%0D%0A%0D%0ABitte korrigieren Sie folgende Informationen%0D%0AXXX PLATZHALTER – BITTE ANPASSEN XXX%0D%0A%0D%0ABitte reichen Sie folgende Nachweise nach%0D%0AXXX PLATZHALTER – BITTE ANPASSEN XXX%0D%0A%0D%0A%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,");
        }
        if (value == "training") {
          window.open("mailto:" + ecrf_user.email + "?subject=eCRF Marvin –Antrag: eCRF-Trainings Zugang aktiviert [" + application.ecrf_trial_configuration.short + "], " + ecrf_user.name + ", " + ecrf_user.first_name + "&body=Guten Tag " + ((ecrf_user.person_title) ? ecrf_user.person_title + " " : "") + ecrf_user.first_name + " " + ecrf_user.name + ",%0D%0A%0D%0AWir haben Ihren Antrag auf eCRF-Zugang und / oder der studienspezifischen Nachweise erhalten. Sie können nun das MARVIN online Training absolvieren.%0D%0A%0D%0ADie Zugangsdaten erhalten Sie direkt aus dem Marvin-System mit dem Absender „noreply@xclinical.com“ mit folgendem Betreff „Your participation in project …“. Prüfen Sie hierzu ggf. Ihren Spam-Ordner. Das Training finden Sie unter „Info Hilfe“ unter „eLearning – Marvin for Sites“…….%0D%0ABei Rückfragen wenden Sie sich bitte an eCRF.account.support@nct-heidelberg.de.%0D%0A%0D%0AMit freundlichen Grüßen,");
        }
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeChecked = (file_id) => {
    const currentComponent = this;
    const ecrf_user = this.state.ecrf_users.find(user => user.files.map(file => file.id).includes(file_id));
    if (ecrf_user.checked_file_ids && ecrf_user.checked_file_ids.includes(file_id)){
      console.log("setunchecked", ecrf_user, file_id)
      fetch('/ecrf_management/' + ecrf_user.id + '/mark_file_unchecked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich akzeptanz zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      console.log("setchecked", ecrf_user, file_id);
      fetch('/ecrf_management/' + ecrf_user.id + '/mark_file_checked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich als akzeptiert markiert.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleChangeSubmitted = file_id => {
    const currentComponent = this;
    const selectedUser = this.state.ecrf_users.find(user => user.id == this.state.selectedIds["Personen"][0]);
    // const application = selectedUser.ecrf_applications.find(app => app.files.map(file => file.id).includes(file_id));
    if (selectedUser.submitted_file_ids && selectedUser.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + selectedUser.id + '/mark_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          // currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + selectedUser.id + '/mark_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          // currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleChangeSubmittedTrialDocumentation = file_id => {
    const currentComponent = this;
    const selectedUser = this.state.ecrf_users.find(user => user.id == this.state.selectedIds["Personen"][0]);
    const application = selectedUser.ecrf_applications.find(app => app.files.map(file => file.id).includes(file_id));
    console.log(file_id, application, selectedUser);
    if (application.submitted_file_ids && application.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + selectedUser.id + '/mark_application_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + selectedUser.id + '/mark_application_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          application_id: application.id,
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleChangeApplicationFileChecked = (file_id) => {
    const currentComponent = this;
    const ecrf_user = this.state.ecrf_users.find(user => this.state.selectedIds["Personen"][0] == user.id);
    const ecrf_application = ecrf_user.ecrf_applications.find(app => app.files.map(file => file.id).includes(file_id));
    // const application = selectedUser.ecrf_applications.find(app => app.files.map(file => file.id).includes(file_id));
    console.log(ecrf_application, file_id);
    if (ecrf_application.checked_file_ids && ecrf_application.checked_file_ids.includes(file_id)){
      console.log("setunchecked")
      fetch('/ecrf_management/' + ecrf_application.id + '/mark_application_file_unchecked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          // currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich akzeptanz zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      console.log("setchecked", ecrf_application.id);
      fetch('/ecrf_management/' + ecrf_application.id + '/mark_application_file_checked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          // currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich als akzeptiert markiert.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleApplicationFileDelete = application => {
    const currentComponent = this;
    console.log(application);
    fetch('/ecrf_management/' + application.id + '/delete_application_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        // application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // console.log(data);
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationDelete = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + application.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // console.log(data);
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Löschen nicht möglich, da Benutzer bereits authorisiert.', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleEcrfUserReceived = message => {
    console.log("received Ecrf User", message);
    if (message.new_ecrf_user) {
      const ecrf_user = message.new_ecrf_user;
      let newEcrfUsers = [...this.state.ecrf_users]
      newEcrfUsers.push(ecrf_user)
      this.setState({ ecrf_users: newEcrfUsers});
      this.props.enqueueSnackbar('eCRF User wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.new_ecrf_user.email, {variant: 'success'});
    }
    if (message.ecrf_user) {
      const ecrf_user = message.ecrf_user;
      let newEcrfUsers = [...this.state.ecrf_users]
      const ind = this.state.ecrf_users.findIndex(user => user.id == ecrf_user.id);
      newEcrfUsers[ind] = ecrf_user;
      this.setState({ ecrf_users: newEcrfUsers});
      this.props.enqueueSnackbar('eCRF User wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.ecrf_user.email, {variant: 'success'});
    }
    if (message.deleted_ecrf_user_ids) {
      const newEcrfUsers = [...this.state.ecrf_users.filter(eu => !message.deleted_ecrf_user_ids.includes(eu.id))];
      const removedEcrfUsers = this.state.ecrf_users.filter(eu => message.deleted_ecrf_user_ids.includes(eu.id));
      const namesArray = removedEcrfUsers.map(eu => eu.email);
      let newSelectedIds = {...this.state.selectedIds};
      newSelectedIds["Personen"] = this.state.selectedIds["Personen"].filter(euid => !message.deleted_ecrf_user_ids.includes(euid));
      this.setState({ ecrf_users: newEcrfUsers, selectedIds: newSelectedIds });
      this.props.enqueueSnackbar('eCRF User wurde(n) von Benutzer ' + message.username + ' gelöscht. Benutzer entfernt: ' + namesArray, {variant: 'success'});
    }
  }

  render() {
    const { openDialogs } = this.state;
    const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF"}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5"}, {value: "all complete", label: "Alles vollständig", tooltipText: "Alle Angaben und Nachweise der Person sind vollständig", backgroundColor: "#76d658"}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD"}];
    const accountStatusOptions =  [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF", stepnr: 1}, {value: "training", label: "Trainingsrolle aktiviert", tooltipText: "Der Account des Benutzers wurde ohne Berechtigungen zu Trainingszwecken aktiviert", backgroundColor: "#FFFFFF", stepnr: 2}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393", stepnr: 3}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5", stepnr: 3}, {value: "ready", label: "Bereit", tooltipText: "Antrag ist genehmigt, aber Prüfzentrum oder Studie ist nicht freigeschaltet", backgroundColor: "#f5b556", stepnr: 3}, {value: "active", label: "Aktiv", tooltipText: "eCRF-Account ist freigeschaltet und aktiv.", backgroundColor: "#76d658", stepnr: 4}, {value: "locked", label: "Gesperrt", tooltipText: "eCRF-Account ist vom DM gesperrt worden", backgroundColor: "#FF4917", stepnr: 4}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD", stepnr: 4}];
    const annotatedEcrfUsers = this.state.ecrf_users.map(user => {
      const ret = {...user, personStatusArray: personStatusOptions.find(opt => opt.value == user.person_status)}
      return ret;
    })
    // console.log(annotatedEcrfUsers);
    const selectedPeople = (this.state.selectedIds && this.state.selectedIds["Personen"]) ? annotatedEcrfUsers.filter(ecrfu => this.state.selectedIds["Personen"].includes(ecrfu.id )) : null;
    const selectedNotes = (selectedPeople && (this.state.selectedIds && this.state.selectedIds["Story Board"])) ? selectedPeople[0].polymorphic_notes.filter(note => this.state.selectedIds["Story Board"].includes(note.id)) : [];
    // console.log(selectedPeople);
    // const disabledFields = ["date_submitted", "status"]
    // const hiddenFields = ["username"];
    // const rolesOptions = [{value: "TestRolle1", label: "TestRolle1"}, {value: "TestRolle2", label: "TestRolle2"}, {value: "TestRolle3", label: "TestRolle3"}, {value: "TestRolle4", label: "TestRolle4"}]
    // const functionsOptions = [{value: "Prüfstelle", label: "Prüfstelle"}, {value: "TestFunction2", label: "TestFunction2"}, {value: "TestFunction3", label: "TestFunction3"}, {value: "TestFunction4", label: "TestFunction4"}]
    // const sitesOptions = [{value: "TestSite1", label: "TestSite1"}, {value: "TestSite2", label: "TestSite2"}, {value: "TestSite3", label: "TestSite3"}, {value: "TestSite4", label: "TestSite4"}]
    // const statusOptions = [{value: "applied", label: "Eingereicht"}, {value: "granted", label: "Genehmigt"}, {value: "denied", label: "Abgelehnt"}, {value: "TestSite4", label: "TestSite4"}]
    // const applicationSelectOptions = {trial_id: trialsOptions, role: rolesOptions, site: sitesOptions, function: functionsOptions, status: statusOptions}
    const requiredFields = ["name", "first_name", "email", "institution_name", "street", "zip", "city", "consent"];
    const statusOptions = [{value: "applied", label: "Eingereicht", stepnr: 2}, {value: "granted", label: "Genehmigt", stepnr: 3}, {value: "denied", label: "Abgelehnt", stepnr: 3}, {value: "waiting", label: "Warten auf Antragsupload", stepnr: 1}]
    // const handleDoubleClick = () => {

    // }
    const disabledFields = ["date_submitted", "status"]
    const hiddenFields = ["username"];
    // const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung"}, {value: "checking", label: "zu prüfen durch DM"}, {value: "user interaction necessary", label: "User Eingabe nötig"}, {value: "all complete", label: "Alles vollständig"}, {value: "misc", label: "Sonstiges"}];
    
    // console.log(selectedPeople);
    let functionsOptions = {};
    let rolesOptions = {};
    let sitesOptions = {};
    let trialsOptions = {};
    if (selectedPeople && selectedPeople.length == 1) {
      const appliedTrialIds = (selectedPeople[0].ecrf_applications) ? selectedPeople[0].ecrf_applications.map(appl => appl.ecrf_trial_configuration.id) : [];
      trialsOptions = this.props.trials.filter(trial => !(appliedTrialIds.includes(trial.id))).map(trial => {return {value: trial.id, label: trial.short}});
      console.log(appliedTrialIds, trialsOptions);
      console.log(selectedPeople[0].checked_file_ids);
      
      this.props.trials.forEach(conf => {
        // let ret = [];
        const func_ret = conf.ecrf_trial_functions.map(func => {
          return ({value: func.id, label: func.name});
        });
        functionsOptions = {...functionsOptions, [conf.id]: func_ret};
      })
      
      this.props.trials.forEach(conf => {
        let func_ret = [];
        conf.ecrf_trial_functions.forEach(func => {
          const roles_ret = func.ecrf_trial_roles.map(role => {
            return ({value: role.id, label: role.name});
          })
          func_ret = {...func_ret, [func.id]: roles_ret};
        });
        rolesOptions[conf.id] = func_ret;
      })
      console.log(rolesOptions);
      
      this.props.trials.forEach(conf => {
        const sites = conf.trial.sites.map(site => {
          return {value: site.id, label: site.inst_description};
        })
        sitesOptions[conf.id] = sites;
      })
    } else {
      console.log(selectedPeople)
    }
    const applicationSelectOptions = {trial_id: trialsOptions || "", role: rolesOptions, site: sitesOptions, function: functionsOptions, status: accountStatusOptions}
    // (selectedPeople) ? console.log(selectedPeople[0].polymorphic_notes) : null;
    let deleteSubList = {};
    if (openDialogs.deleteUser && selectedPeople.length > 0) {
      selectedPeople.forEach(person => {
        deleteSubList[person.email] = {"Anträge": person.ecrf_applications.map(application => application.ecrf_trial_configuration.short)}
      })
    }
    console.log(selectedPeople)
    console.log(deleteSubList);
    return (
      <Grid container direction="row" justify="center" alignItems="top">
        <ActionCableConsumer
          channel={{channel: "EcrfManagementChannel"}}
          onReceived={this.handleEcrfUserReceived}
        />
        { (openDialogs["editPersonen"]) &&
        <EditDialog
          title="ECRF Benutzer editieren"
          admin
          obj={selectedPeople[0]}
          open={openDialogs.editPersonen}
          handleSave={this.handleEditEcrfUserSave}
          handleClose={() => this.handleClose("editPersonen")}
          Fields={EcrfUserFields}
          requiredFields={requiredFields}
          selectOptions={{person_status: personStatusOptions}}
        />}
        {/* { (openDialogs.newApplication) &&
        <NewDialog
          title="Studiendaten"
          obj={{date_submitted: Moment().toISOString().substr(0,10), status: "applied"}}
          open={openDialogs.newApplication}
          handleSave={this.handleNewApplicationSave}
          handleClose={() => this.handleClose("newApplication")}
          Fields={EcrfApplicationFields}
          // requiredFields={requiredFields}
          selectOptions={applicationSelectOptions}
          disabledFields={disabledFields}
          hiddenFields={hiddenFields}
        />} */}
        { (openDialogs.newUser) &&
        <NewDialog
          title="ECRF Benutzer anlegen"
          admin
          obj={{person_status: "in preparation"}}
          open={openDialogs.newUser}
          handleSave={this.handleNewEcrfUserSave}
          handleClose={() => this.handleClose("newUser")}
          Fields={EcrfUserFields}
          requiredFields={requiredFields}
          selectOptions={{person_status: personStatusOptions}}
          // disabledFields={disabledFields}
          // hiddenFields={hiddenFields}
        />}
        { (openDialogs.mailToUser) &&
        <EcrfMailToUserDialog
          title="E-Mail an ECRF User"
          open={openDialogs.mailToUser}
          handleClose={() => this.handleClose("mailToUser")}
          email={selectedPeople[0].email}
          last_name={selectedPeople[0].name}
          first_name={selectedPeople[0].first_name}
          person_title={selectedPeople[0].title}
        />
        }
        { (openDialogs.deleteUser && selectedPeople.length > 0) &&
        <DeleteConfirmationDialog
          title={"Benutzer löschen"}
          open={openDialogs.deleteUser}
          handleSave={this.handleDeleteEcrfUser}
          handleClose={() => this.handleClose("deleteUser")}
          mainNames={selectedPeople.map(person => person.email)}
          subNames={deleteSubList}
        />}
        <Grid item xs={4}>
          <EnhancedTable
            title={"Personen"}
            data={annotatedEcrfUsers}
            columns={peopleColumns}
            // singleSelect={!this.state.multipleSelectMode}
            multipleSelectable
            selectedIds={(this.state.selectedIds) ? this.state.selectedIds["Personen"] : []}
            selectedObjects={selectedPeople}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Personen")}
            handleAddClick={() => this.handleOpen("newUser")}
            // handleInfoClick={this.handleInfoInstitutionClick}
            handleDeleteClick={() => this.handleOpen("deleteUser")}
            handleFilterClick={this.handleInstitutionsFilterClick}
            filters={this.state.peopleFilters}
            onFilterChange={this.handlePeopleFilterChange}
            rowsPerPage={25}
            orderBy="name"
            order="asc"
            onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Personen")}
            allData={annotatedEcrfUsers}
            autoFill
            selectable
            expanded={true}
            handleExpandChange={this.handleExpandChange}
            objectType="EcrfTrialConfiguration"
            // noAddIcon
          />
        </Grid>
        <Grid item xs={8}>
          { (selectedPeople != null && selectedPeople.length == 1) &&
            <Paper style={{borderStyle: "solid", width: '100%', borderWidth: "1px"}}>
              <Box direction="row" justifyContent="flex_end" alignItems="center" >
                {/* { (openDialogs.editUser) &&
                <EditDialog
                  title="Benutzer editieren"
                  obj={this.state.user}
                  open={openDialogs.editUser}
                  handleSave={this.handleEditEcrfUserSave}
                  handleClose={() => this.handleClose("editUser")}
                  Fields={EcrfUserFields}
                  requiredFields={requiredFields}
                  disabled={(this.state.user.person_status == "all complete" || this.state.user.person_status == "misc")}
                />} */}
                <Tooltip title="Editieren">
                  <IconButton aria-label="Edit" onClick={() => this.handleOpen("editPersonen")}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Email verfassen">
                  <IconButton aria-label="MailTo" onClick={() => this.handleOpen("mailToUser")}>
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <EcrfUserFields
                obj={selectedPeople[0]}
                readOnly
                requiredFields={requiredFields}
                selectOptions={{person_status: personStatusOptions}}
                admin
                reduced={true}
              />
              <Grid container direction="row" justify="center" alignItems="top">
                <Grid item xs={12}>
                  { (openDialogs.newApplication && selectedPeople.length == 1) &&
                  <NewDialog
                    title="Studiendaten"
                    obj={{date_submitted: Moment().toISOString().substr(0,10), status: "in preparation"}}
                    open={openDialogs.newApplication}
                    handleSave={this.handleNewApplicationSave}
                    handleClose={() => this.handleClose("newApplication")}
                    Fields={EcrfApplicationFields}
                    // requiredFields={requiredFields}
                    selectOptions={applicationSelectOptions}
                    disabledFields={disabledFields}
                    hiddenFields={hiddenFields}
                  />}
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                    <Typography variant="h6" color="inherit" style={{marginLeft: 20, marginTop: 20}}>
                      Studienspezifische Zugangsanträge und Nachweise
                    </Typography>
                    <Toolbar
                      variant='dense'
                    >
                      <Tooltip title="Neuer Antrag" style={{marginRight: 5}}>
                        <IconButton aria-label="Edit" >
                          <Button 
                            variant="contained"
                            onClick={() => this.handleOpen("newApplication")}
                            size="small"
                          > 
                            Neuen Antrag anlegen
                          </Button>
                        </IconButton>
                      </Tooltip>
                    </Toolbar>
                  </Box>
                  <ListOfEcrfApplications
                    user={selectedPeople[0]}
                    admin={true}
                    trials={this.props.trials}
                    // applications={this.state.user.ecrf_applications}
                    statusOptions={accountStatusOptions}
                    handleApplicationFileUpload={this.handleApplicationFileUpload}
                    // handlePdfDownload={this.handlePdfDownload}
                    handleFileDelete={this.handleApplicationFileDelete}
                    handleDialogOpen={this.handleOpen}
                    handleEditApplicationStatusSave={this.handleEditApplicationStatusSave}
                    handleEditApplicationSave={this.handleEditApplicationSave}
                    handleChangeChecked={this.handleChangeApplicationFileChecked}
                    handleChangeSubmitted={this.handleChangeSubmittedTrialDocumentation}
                    handleChangeAcceptedTrialDocumentation={this.handleChangeAcceptedTrialDocumentation}
                    handleChangeApplicationStatus={this.handleChangeApplicationStatus}
                    handleDelete={this.handleApplicationDelete}
                    // handleRemove={this.handleApplicationRemove}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                    <Typography variant="h6" color="inherit" style={{marginLeft: 20, marginTop: 20}}>
                      Allgemeine Nachweise
                    </Typography>
                    <FormControlLabel control={<Switch size="small" checked={selectedPeople[0].accepted_certificates} onChange={() => this.handleChangeAccepted(selectedPeople[0].id)}/>} label="Alle Dokumente sind vollständig und akzeptiert" />
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap">
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <InputLabel filled animated shrink margin="dense" variant="outlined" >Übertragene Dateien</InputLabel>
                    </FormControl>
                    { (!this.state.locked) &&
                      <Tooltip title="Neue Datei hochladen">
                        <IconButton aria-label="Upload" onClick={() => this.handleOpen("fileUpload")} size="small">
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Box>
                  { (this.state.openDialogs["fileUpload"]) &&
                  <FileUploadDialog
                    open={this.state.openDialogs.fileUpload}
                    // trialId={this.state.trial.id}
                    saveUrl={'/ecrf_management/' + selectedPeople[0].id + '/upload_file_admin'}
                    handleFileUpload={this.handleFileUpload}
                    handleClose={() => this.handleClose("fileUpload")}
                  />}
                  <ListOfFiles 
                    associatedObjectId={selectedPeople[0].id}
                    files={selectedPeople[0].files}
                    admin
                    checkable
                    handleDelete={this.handleFileDelete} 
                    disabled={false} 
                    submitted_file_ids={selectedPeople[0].submitted_file_ids || []} 
                    checked_file_ids={selectedPeople[0].checked_file_ids || []}
                    handleChangeChecked={this.handleChangeChecked}
                    handleChangeSubmitted={this.handleChangeSubmitted}
                    controller="ecrf_management"
                    action="download_ecrf_file"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="person_status"
                    label="Status"
                    value={selectedPeople[0].person_status}
                    fullWidth
                    variant="outlined"
                    style={{zIndex: 989}}
                    handleChange={this.handleChangePersonStatus}
                    // required={props.requiredFields.includes("status")}
                    InputProps={{
                      readOnly: false,
                    }}
                    type="select"
                    options={personStatusOptions}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  { (this.state.openDialogs["newNote"]) &&
                    <NewNoteDialog
                      // handleOpen={this.props.handleOpen}
                      noteableId={selectedPeople[0].id}
                      noteableType={"EcrfUser"}
                      handleClose={() => this.handleClose("newNote")}
                      handleSave={this.handleNewNoteSave}
                      // trialId={this.state.trial.id}
                      // selectedSite={selectedSitesWithStatus[0]}
                      // selectedDepartment={this.state.selectedDepartments[0]}
                      open={this.state.openDialogs["newNote"]}
                    />
                  }
                  { (selectedNotes.length == 1 && this.state.openDialogs["editStory Board"]) &&
                    <EditNoteDialog
                      open={this.state.openDialogs["editStory Board"]}
                      // trialId={this.state.trial.id}
                      handleClose={() => this.handleClose("editStory Board")}
                      // sites={this.state.sites}
                      handleSave={this.handleEditNoteSave}
                      // this.selectedSites[0].inst_description
                      selectedNote={selectedNotes[0]}
                    />
                  }
                  <EnhancedTable
                    title={"Story Board"}
                    data={(selectedPeople[0].polymorphic_notes) ? selectedPeople[0].polymorphic_notes : []}
                    columns={noteColumns}
                    // singleSelect={!this.state.multipleSelectMode}
                    multipleSelectable
                    selectedIds={this.state.selectedIds["Story Board"]}
                    selectedObjects={selectedNotes}
                    onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Story Board")}
                    handleAddClick={() => this.handleOpen("newNote")}
                    // handleInfoClick={this.handleInfoSiteClick}
                    // handleEditClick={this.handleEditSiteClick}
                    // handleRemoveClick={this.handleRemoveSiteClick}
                    handleDeleteClick={this.handleDeleteUserNotes}
                    // handleFilterClick={this.handleSitesFilterClick}
                    // filters={this.state.siteFilters}
                    rowsPerPage={50}
                    orderBy="entry_date"
                    order="desc"
                    onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "Story Board")}
                    // markedIds={this.state.selectedDepartmentsSitesIds}
                    // onFilterChange={this.handleSitesFilterChange}
                    allData={selectedPeople[0].polymorphic_notes}
                    selectable
                    expanded={true}
                    // handleExpandChange={this.handleExpandChange}
                    superType="EcrfTrialConfiguration"
                  />
                </Grid>
              </Grid>
            </Paper>
          }
        </Grid>
      </Grid>
    )
  }
}

EcrfManagement.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(EcrfManagement));
