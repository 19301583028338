import React from 'react';
// import { useState } from "react"; 
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can.js';
import moment from 'moment';

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
//import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from './TextField.jsx';
import Card from "@mui/material/Card"; 
import Collapse from "@mui/material/Collapse"; 
import CardHeader from "@mui/material/CardHeader"; 
import Container from "@mui/material/Container"; 
import CardContent from "@mui/material/CardContent"; 
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; 
import KeyboardArrowUpIcon from  "@mui/icons-material/KeyboardArrowUp"; 
import IconButton from "@mui/material/IconButton"; 
import CircularProgress from '@mui/material/CircularProgress';

// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ViewDialog from './ViewDialog.jsx';
import SarcBobFileUploadDialog from './SarcBobFileUploadDialog.jsx';
import SearchBar from './SearchBar.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import { CheckBox, ImportContactsOutlined, Label, ThreeSixty, ViewInArOutlined } from '@mui/icons-material';
import EnhancedTable from './EnhancedTable.jsx';
import SarcPatientFields from './SarcPatientFields.jsx';
import SarcPatientDiagnosisFields from './SarcPatientDiagnosisFields.jsx';
import SarcPatientPTnmFields from './SarcPatientPTnmFields.jsx';
import SarcPatientStadiumFields from './SarcPatientStadiumFields.jsx';
import SarcPatientOperationFields from './SarcPatientOperationFields.jsx';
import SarcPatientSystemicTherapyFields from './SarcPatientSystemicTherapyFields.jsx';
import SarcPatientMiscellaneousTherapyFields from './SarcPatientMiscellaneousTherapyFields.jsx';
import SarcPatientRadiotherapyFields from './SarcPatientRadiotherapyFields.jsx';
import SarcPatientNuclearTherapyFields from './SarcPatientNuclearTherapyFields.jsx';
import SarcPatientProgressionFields from './SarcPatientProgressionFields.jsx';
import SarcPatientVitalStatusFields from './SarcPatientVitalStatusFields.jsx';
import SarcPatientSocioFields from './SarcPatientSocioFields.jsx';
// import SarcPatientPROFields from './SarcPatientPROFields.jsx';
import SarcPatientBloodSampleFields from './SarcPatientBloodSampleFields.jsx';
// import SarcPatientTissueSampleFields from './SarcPatientTissueSampleFields.jsx';
import Stack from '@mui/material/Stack';


//import Chart from 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { Chart as ReactChart } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js'; 
Chart.register(CategoryScale);
Chart.register(...registerables);

import Papa from "papaparse";
import DataEntryEditDialog from './DataEntryEditDialog.jsx';
import { border } from '@mui/system';
import Link from '@mui/material/Link';
// import moment from 'moment';

const patientsColumns = [
  {
   id: "pseudonym",
   secondary: "pseudonym",
   label: "Pseudonym",
   numeric: false,
   disablePadding: false,
   searchable: true,
  },
 ];

class SarcbopRequestAccess extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const user_id = urlParams.get('user_id');
    const project = urlParams.get('project');
    // console.log(project);
    this.state = {
      user: user,
      isChecked: false,
      requested_user_id: user_id,
      requested_project: project,
      requested_heroes_aya_users: this.props.requested_heroes_aya_users,
      requested_sarcbop_users: this.props.requested_sarcbop_users,
      sarcbop_users: this.props.sarcbop_users,
      heroes_aya_users: this.props.heroes_aya_users
    };
    this.form = React.createRef();
  }
  
  componentDidMount(props) {
    
  }

  handleClickAccept = (user_id, project, request) => {
    let currentComponent = this;
    // if(this.state.isChecked == true){
      fetch('/sarcbop/granting_access', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          project: project,
          user_id: user_id,
          request: request
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          if(request == "accepted"){
            currentComponent.props.enqueueSnackbar('Der Antrag auf Zugang wird genehmigt.', {variant: 'success'});
          }
          else {
            currentComponent.props.enqueueSnackbar('Der Antrag auf Zugang wird abgelehnt.', {variant: 'success'});
          }
          
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
        }
        
      })
    // }
    // else {
    //   currentComponent.props.enqueueSnackbar('Wenn Sie eine Zugangsberechtigung wünschen, Kreuzen Sie bitte das Kästchen an.', {variant: 'error'});
    // }
   
  } 

  handleClickRequest = (project_name) => {
    let currentComponent = this;
  
      fetch('/sarcbop/request_access_progress', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          project: project_name
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        if (data.response == 1) {
          
          currentComponent.props.enqueueSnackbar('Wir haben eine E-Mail an den Projektleiter geschickt. Ihre Zugangsberechtigung wird in Kürze erteilt werden.', {variant: 'success'});
        } else {
          // console.log(data.response);
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
        }
        
      })
    
  }
  handleRequestReceived = message => {
    console.log("Authorization requests received");
    console.log(message);
    if (message.requested_sarcbop_users) {
      this.setState({ requested_sarcbop_users: message.requested_sarcbop_users });
    }
    if (message.requested_heroes_aya_users) {
      this.setState({ requested_heroes_aya_users: message.requested_heroes_aya_users });
    }
    if (message.sarcbop_users) {
      this.setState({sarcbop_users: message.sarcbop_users });
    }
    if (message.heroes_aya_users) {
      this.setState({ heroes_aya_users: message.heroes_aya_users });
    }
  }
  handleConnected = event => {
    console.log("connected");
  }
  

  render(props) {
    if(this.state.user.roles){
      var sarbop_admin = this.state.user.roles.map(role => {
        return role.sarcbop_admin;
      });
      var heroes_aya_admin = this.state.user.roles.map(role => {
        return role.heroes_aya_admin;
      });
    }
    
    if(sarbop_admin.includes(true) || heroes_aya_admin.includes(true) ){  
      
        return (
          <Box item xs={12} padding={"50px"}>
            <ActionCableConsumer
              channel={{channel: "SarcAuthorizationChannel"}}
              onReceived={this.handleRequestReceived}
              onConnected={this.handleConnected}
            />
             <Table>
              <TableCell>
                {/* <h3 style={{fontFamily: "sans-serif", color: "#515151"}}>Möchten Sie eine Zugangsberechtigung erteilen? </h3> */}
                {/* {sarbop_admin.includes(true)  &&  this.state.sarcbop_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>SarcBOP</h4> } */}
                {sarbop_admin.includes(true)  &&  this.state.requested_sarcbop_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>Angeforderte Benutzer SarcBOP</h4> }
                  
                  {sarbop_admin.includes(true) && this.state.requested_sarcbop_users.map(user => {
                    return (
                      <TableRow>
                        <TableCell>{user.name}</TableCell>
                        <TableCell><Link href={'mailto:' + user.email} target="_blank" underline='hover' fullWidth>{user.email}</Link></TableCell> 
                        <TableCell><Button onClick={() => this.handleClickAccept(user.id, "SarcBOP", "accepted")} color="primary"  variant="contained">Genehmigen</Button></TableCell>
                        <TableCell><Button onClick={() => this.handleClickAccept(user.id, "SarcBOP", "rejected")} color="primary"  variant="contained">Ablehnen</Button></TableCell>
                      </TableRow>
                    )
                  })}
                  {heroes_aya_admin.includes(true) &&  this.state.requested_heroes_aya_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>Angeforderte Benutzer Heroes-AYA</h4>}
                  {heroes_aya_admin.includes(true) && this.state.requested_heroes_aya_users.map(user => {
                    return (
                      <TableRow>
                        <TableCell>{user.name}</TableCell> 
                        <TableCell><Link href={'mailto:' + user.email} target="_blank" underline='hover' fullWidth>{user.email}</Link></TableCell> 
                        <TableCell><Button onClick={() => this.handleClickAccept(user.id, "Heroes-AYA", "accepted")} color="primary"  variant="contained">Genehmigen</Button></TableCell> 
                        <TableCell><Button onClick={() => this.handleClickAccept(user.id, "Heroes-AYA", "rejected")} color="primary"  variant="contained">Ablehnen</Button></TableCell>
                      </TableRow>
                    
                    )
                  })}  
                  </TableCell>
                  <TableCell>
                    {sarbop_admin.includes(true)  &&  this.state.sarcbop_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>Berechtigte Benutzer SarcBOP</h4> }
                    {sarbop_admin.includes(true) && this.state.sarcbop_users.map(user => { 
                      return (
                        <TableRow>
                          <TableCell>{user.name}</TableCell>
                          <TableCell><Link href={'mailto:' + user.email} target="_blank" underline='hover' fullWidth>{user.email}</Link></TableCell>
                          <TableCell><Button onClick={() => this.handleClickAccept(user.id, "SarcBOP", "delete_access")} color="primary"  variant="contained">Berechtigung Entfernen</Button></TableCell>
                        </TableRow> 
                      )
                    })}
                  
                    {/* {heroes_aya_admin.includes(true) &&  this.state.heroes_aya_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>Heroes-AYA</h4>} */}
                    
                    {heroes_aya_admin.includes(true)  &&  this.state.heroes_aya_users.length >0 && <h4 style={{fontFamily: "sans-serif", color: "#515151"}}>Berechtigte Benutzer Heroes-AYA</h4> }
                    {heroes_aya_admin.includes(true) && this.state.heroes_aya_users.map(user => {
                      return (
                        <TableRow>
                          <TableCell>{user.name}</TableCell> 
                          <TableCell><Link href={'mailto:' + user.email} target="_blank" underline='hover' fullWidth>{user.email}</Link></TableCell>
                          <TableCell><Button onClick={() => this.handleClickAccept(user.id, "Heroes-AYA", "delete_access")} color="primary"  variant="contained">Berechtigung Entfernen</Button></TableCell>
                        </TableRow> 
                      )
                    })}
                  </TableCell>
                </Table>  
            </Box>
      )
   
    } 
    else {
      return (
        <Box item xs={12} padding={"150px"} textAlign={"center"}>
          <h2 style={{fontFamily: "sans-serif", color: "#515151"}}>Sie sind momentan keiner Gruppe zugeordnet, die Zugriff auf Daten hat.</h2>
          <h3 style={{fontFamily: "sans-serif", color: "#515151"}}>Zugriff beantragen für:</h3>
          <Stack direction="row" spacing={2} margin={"auto"} display={"inline"}>
            
            <Button alignItems="center"onClick={() => this.handleClickRequest("sarcbop")} color="primary"  variant="contained">
              SarcBOP
            </Button>  
            
            <Button onClick={() => this.handleClickRequest("heroes_aya")} color="primary"  variant="contained">
              Heroes-AYA
            </Button> 
            
          
            <Button onClick={() => this.handleClickRequest("master")} color="primary"  variant="contained">
              Master
            </Button> 
          </Stack> 
          
        </Box>
        
      )
    }
    
  }
}

SarcbopRequestAccess.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(SarcbopRequestAccess));
