import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function EditDialog({ Fields, ...props }) {

  const [obj, setObj] = useState({ ...props.obj });
  // all fields with errors false 
  //const [errors, setErrors] = useState(Object.keys(obj).reduce((obj, v) => ({ ...obj, [v]: false }), {}))
  const [errors, setErrors] = useState([]);

  function objChanged() {
    Object.keys(obj).forEach(key => {
      if (obj[key] != props.obj[key]) {
        return false;
      }
      return true;
    })
  }

  function handleChange(event, meta=null) {
    let newObj = obj;
    const target = event.target;
    let value = target.value;
    if (target.type == "checkbox") {
      const name = target.name;
      newObj[name] = meta;
    } else {
      const name = target.id || target.name;
      newObj[name] = value;
    }
    setObj({ ...newObj });
  };

  function handleSelectChange(event) {
    console.log("handleSelectChange", event);
    let newObj = obj;
    const target = event.target;
    const value = target.value;
    const name = target.name || target.id;
    console.log(name);
    newObj[name] = value;
    setObj({ ...newObj });
  };

  function onCreateOption(test) {
    let newObj = {...obj};
    newObj["ecrf_trial_configuration_id"] = value;
    // this.setState({inputValue: newValue});
    setObj({...newObj});
  }

  function handleInputValueChange(newValue, actionMeta) {
    if ((actionMeta.action != "input-blur") && (actionMeta.action != "menu-close")) {
      console.log(newValue, actionMeta);
      this.setState({inputValue: newValue});
    }
  }

  function handleInput(event) {
    // detects errors in inputfields
    const target = event.target;
    const name = target.id || target.name;
    if (!target.validity.valid) {
      if (!errors.includes(name)) {
        setErrors([...errors, name]);
      }
    } else {
      // resets errors
      if (errors.includes(name)) {
        let temp = errors
        temp.splice(temp.indexOf(name), 1)
        setErrors([...temp]);
      }
    }
  }

  function onCreateOption(value) {
    let newObj = {...obj};
    newObj["name"] = value;
    // this.setState({inputValue: newValue});
    setObj({...newObj});
  }

  const handleChangeSelect = (selected) => {

    console.log(selected);
  }

  function isSuperset(set, subset) {
    for (const elem of subset) {
      if (!set.has(elem)) {
        console.log(elem);
        return false;
      }
    }
    return true;
  }

  function handleSave(event) {
    console.log("test");
    props.handleSave({
      ...obj}
    )
  }

  function addObjValue(field, value) {
    let newObj = obj;
    newObj[field] = value;
    setObj({ ...newObj });
  }


  // const filledFields = [];
  // for(var key in obj){
  //   if (obj[key]) {
  //     filledFields.push(key);
  //   }
  // }
  // const filledFields = Object.keys(obj).map(key => (obj[key] != null) && (obj[key] != ""));
  let requiredFieldsFilled = true;
  for (let field in props.requiredFields) {
    if (obj[props.requiredFields[field]] == null || obj[props.requiredFields[field]] == "" || obj[props.requiredFields[field]] == false) {
      requiredFieldsFilled = false;
      break;
    }
  }

  const saveable = requiredFieldsFilled;
  // console.log(props.requiredFields, requiredFieldsFilled);
  // console.log(obj);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      PaperProps={props.paperProps}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Fields obj={obj}
          handleChange={handleChange} handleSelectChange={handleSelectChange} handleInputValueChange={handleInputValueChange} onCreateOption={onCreateOption} handleInput={handleInput} addObjValue={addObjValue} requiredFields={props.requiredFields} errors={errors} additionalData={props.additionalData} selectOptions={props.selectOptions} readOnly={props.disabled} originalObj={props.obj} objChanged={objChanged} admin={props.admin}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" data-cy={"cancle " + props.title}>
          Abbrechen
        </Button>
        <Button
          onClick={() => props.handleSave({
            ...obj
          }
          )}
          disabled={!(saveable && errors.length == 0)}
          color="primary"
          data-cy={"save " + props.title}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// export default EditDialog;
