import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import DropDown from './CustomComponents/DropDown.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";

// import { Progress } from 'react-sweet-progress';
import LinearProgress from '@mui/material/LinearProgress';
// import "react-sweet-progress/lib/style.css";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(
  LinearProgress
);

export default function TrialAdditionalInformation(props) {
  // const classes = useStyles();
  let statusList = [];
  let selectedStatusObject = {};
  let selectedStatus = {};
  if (props.statuses) {
    statusList = props.statuses.map(status => {
      return { value: status.id, label: status.text, color: status.color }
    });
    selectedStatusObject = statusList.find(status => status.value == props.trial.status_id);
    selectedStatus = { value: props.trial.status_id, label: selectedStatusObject.label };
  }


  let sponsorList = [];
  let selectedSponsor = {};
  if (props.sponsors) {
    let name = props.trial.sponsor
    // flatMap removes sponsor null, can be extendet to remove more sponsors 
    sponsorList = props.sponsors.flatMap(sponsor => sponsor == null ? [] : [{ value: sponsor, label: sponsor }] );
    selectedSponsor = { value: name, label: name };
  }


  const phaseList = [{ value: "I", label: "I" }, { value: "I/II", label: "I/II" }, { value: "II", label: "II" }, { value: "III", label: "III" }, { value: "IV", label: "IV" }, { value: "V", label: "V" }];
  const trialTypeList = [{ value: "AMG", label: "AMG (Arzneimittelgesetz)" }, { value: "AWB", label: "AWB (Anwendungsbeobachtung)" }, { value: "MPG", label: "MPG (Medizinproduktegesetz)" }, { value: "Register", label: "Registerstudie" }, { value: "epidemiologisch", label: "Epidemiologische Studie" }, { value: "NIS", label: "NIS (Nichtinterventionelle Studie)" }, { value: "PAES", label: "PAES (Post Authorization Efficacy Study)" }, { value: "PASS", label: "PASS (Post Authorization Safety Study)" }, { value: "sonstige", label: "sonstige" }];
  const iitList = [{ value: true, label: "IIT (Investigator Initiated Trial)" }, { value: false, label: "IIS (Industry Initiated Study)" }];


  const selectedTrialType = trialTypeList.find(tt => tt.value == props.trial.trial_type);
  const legalBasisOptions = [{value: "EU-CTR", label: "EU-CTR"}, {value: "AMG", label: "AMG"}];
  // {value: props.trial.trial_type, label: props.trial.trial_type};
  const selectedPhase = { value: props.trial.phase, label: props.trial.phase };
  const iit = iitList.find(iit => iit.value == props.trial.iit);
  let selectedIit = null;
  let iitName = null;
  if (iit) {
    selectedIit = iit.value;
    iitName = iit.value ? "IIT" : "IIS";
  }
  const onlyNumber = /\d+/;
  // const isOnlyNumber = ((this.state.trial.num_pat_goal.match(onlyNumber) == this.state.trial.num_pat_goal) && (this.state.trial.num_patients_included.match(onlyNumber) == this.state.trial.num_patients_included)); 
  const percentageRecruited = (props.trial.num_pat_goal > 0 && props.trial.num_patients_included > 0) ? ((props.trial.num_patients_included / props.trial.num_pat_goal) * 100).toFixed(1) : 0;

  return (

    <div style={{ backgroundColor: props.trial.status.color, padding: '10px' }}>
      {props.readOnly ?
        <TextField
          margin="dense"
          id="status"
          label="Status"
          value={props.trial.status.text}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (props.trial.status != null) }}
          type="string"
          size="small"
        />
        :
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel filled animated shrink margin="dense" variant="outlined">Status</InputLabel>
          <Select
            id="status_id"
            name="status_id"
            value={selectedStatus}
            options={statusList}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              width: "100%"
            }}
            onChange={props.handleChange2}
            size="small"
          />
        </FormControl>
      }
      <TextField
        margin="dense"
        id="legal_basis"
        label="Rechtsgrundlage"
        value={props.trial.legal_basis}
        fullWidth
        variant="outlined"
        InputProps={{
          readOnly: props.readOnly,
        }}
        InputLabelProps={{ shrink: (props.trial.legal_basis != null) }}
        options={legalBasisOptions}
        handleChange={props.handleChange}
        type="select"
        size="small"
      />
      {props.readOnly ?
        <TextField
          margin="dense"
          id="trial_type"
          label="Studientyp"
          value={props.trial.trial_type}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (props.trial.trial_type != null) }}
          type="string"
          size="small" />
        :
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel filled animated shrink margin="dense" variant="outlined" >Studientyp</InputLabel>
          <Select
            id="trial_type"
            name="trial_type"
            value={selectedTrialType}
            options={trialTypeList}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              width: "100%"
            }}
            onChange={props.handleChange2}
          />
        </FormControl>
      }
      {props.readOnly ?
        <TextField
          margin="dense"
          id="iit"
          label="IIT/IIS?"
          value={iitName}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (props.trial.iit != null) }}
          type="string"
          size="small"
        />
        :
        <FormControl variant="outlined" margin="dense" fullWidth >
          <InputLabel filled animated shrink margin="dense" variant="outlined">IIT/IIS?</InputLabel>
          <Select
            id="iit"
            name="iit"
            value={iit}
            options={iitList}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              width: "100%"
            }}
            onChange={props.handleChange2}
          />
        </FormControl>
      }
      {props.readOnly ?
        <TextField
          margin="dense"
          id="sponsor"
          label="Sponsor"
          value={props.trial.sponsor}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (sponsorList != null) }}
          type="string"
          size="small"
        />
        :
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel filled animated shrink margin="dense" variant="outlined" >Sponsor</InputLabel>
          <CreatableSelect
            id="sponsor"
            name="sponsor"
            value={selectedSponsor}
            options={sponsorList}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              width: "100%"
            }}
            onChange={props.handleChange2}
          />
        </FormControl>

      }
      {props.readOnly ?
        <TextField
          margin="dense"
          id="phase"
          label="Phase"
          value={props.trial.phase}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (props.trial.phase != null) }}
          type="string"
          size="small"
        />
        :
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel filled animated shrink margin="dense" variant="outlined" >Phase</InputLabel>
          <Select
            id="phase"
            name="phase"
            value={selectedPhase}
            options={phaseList}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              width: "100%"
            }}
            onChange={props.handleChange2}
          />
        </FormControl>
      }
      {props.readOnly ?
        <TextField
          margin="dense"
          id="num_pat_goal"
          label="Anzahl Patienten Ziel"
          value={props.trial.num_pat_goal}
          fullWidth
          variant="outlined"
          style={{ zIndex: 1 }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          InputLabelProps={{ shrink: (props.trial.num_pat_goal != null) }}
          type="string"
          size="small"
        />
        :
        <TextField
          margin="dense"
          id="num_pat_goal"
          label="Anzahl Patienten Ziel"
          value={props.trial.num_pat_goal}
          fullWidth
          variant="outlined"
          // style={{zIndex: 1}}
          InputProps={{
            readOnly: props.readOnly,
          }}
          style={{ backgroundColor: "#ffff" }}
          InputLabelProps={{ shrink: (props.trial.num_pat_goal != null) }}
          onChange={props.handleChange}
          error={!((props.trial.num_pat_goal) ? props.trial.num_pat_goal.toString().match(onlyNumber) == props.trial.num_pat_goal.toString() : true)}
          type="string"
          size="small"
        />
      }
      {props.readOnly ?
        <>
          <TextField
            margin="dense"
            id="num_patients_included"
            label="Anzahl Patienten eingeschlossen"
            value={props.trial.num_patients_included}
            fullWidth
            variant="outlined"
            style={{ zIndex: 1 }}
            InputProps={{
              readOnly: props.readOnly,
            }}
            InputLabelProps={{ shrink: (props.trial.num_patients_included != null) }}
            type="string"
            size="small"
          />
          <FormControl variant="outlined" margin="dense" fullWidth style={{ zIndex: 999 }}>
            <InputLabel filled animated shrink margin="dense" variant="outlined" >Rekrutierungsfortschritt</InputLabel>
            {/* <FormControlLabel
                  style={{zIndex: 1}}
                  control={
                    <>
                      
                    </>
                  }
                  label="Rekrutierungsfortschritt"
                /> */}
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" value={(props.trial.num_pat_goal > 0 && props.trial.num_patients_included > 0) ? ((props.trial.num_patients_included / props.trial.num_pat_goal) * 100).toFixed(1) : 0} />
              </Box>
              <Box minWidth={35}>
                {percentageRecruited}%
              </Box>
            </Box>
            {/* <Progress percent={(props.trial.num_pat_goal > 0 && props.trial.num_patients_included > 0) ? ((props.trial.num_patients_included/props.trial.num_pat_goal)*100).toFixed(1) : 0} /> */}

          </FormControl>
        </>
        :
        <TextField
          margin="dense"
          id="num_patients_included"
          label="Anzahl Patienten eingeschlossen"
          value={props.trial.num_patients_included}
          fullWidth
          variant="outlined"
          // style={{zIndex: 1}}
          InputProps={{
            readOnly: props.readOnly,
          }}
          style={{ backgroundColor: "#ffff", zIndex: 992 }}
          InputLabelProps={{ shrink: (props.trial.num_patients_included != null) }}
          onChange={props.handleChange}
          error={!((props.trial.num_patients_included) ? props.trial.num_patients_included.toString().match(onlyNumber) == props.trial.num_patients_included.toString() : true)}
          type="string"
          size="small"
        />
      }
    </div>
  );
}
